window.$ = window.jQuery = require("jquery");
import push from "push.js";

import Vue from 'vue'
import App from './Core.vue'
import router from './router'

import moment from "moment";
import VueMoment from "vue-moment";
import VueMask from "vue-the-mask";
import IdleVue from "idle-vue";
import _ from "lodash";
var languaje = "es";

import store from "./store/";
import vuetify from "./plugins/vuetify.js";
import fun from "./plugins/fun";


import { showWarningConsole } from './plugins/warning.js';



if (languaje == "es") {
    import ("./plugins/const-es.js")
    .then((module) => {
        Vue.prototype.$const = module.default;
    });
} else {
    import ("./plugins/const-en.js")
    .then((module) => {
        Vue.prototype.$const = module.default;
    });
}

//import constapp from cons;
import base from "./plugins/base";
import axios from "./services/Service";
import './registerServiceWorker'

const eventsHub = new Vue();
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: store.state.TimeInactive,
    startAtIdle: false,
});


Vue.use(_);
Vue.use(VueMask);

Vue.prototype.moment = moment;
/* Vue.prototype.$const = constapp; */
Vue.prototype.$fun = fun;
Vue.prototype.$push = push;
Vue.prototype.$http = axios;

axios.interceptors.request.use(
    (config) => {
        const token = ""//localStorage.getItem("token");
        if (token != null) {
            config.headers.common["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }


    },
    (error) => {

        if (error.response == null) {
            fun.alertFull("Error de Conexión", "error");
            return;
        }

        console.log("error.response", error.response);
        let errors = error.response.data.Errors;

        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    fun.alert(errors == null ? error.response.data.Message == null ? error.response.data.title : error.response.data.Message : errors.join(','), "warning");
                    break;
                case 401:

                    localStorage.setItem("isIdle", false);
                    document.onload = null;
                    document.onmousemove = null;
                    document.onmousedown = null;
                    document.ontouchstart = null;
                    document.onclick = null;
                    document.onscroll = null;
                    localStorage.clear();
                    localStorage.removeItem("token");
                    router.push("/");
                    if (localStorage.getItem("token") == null)
                        fun.alert("La sesión ha caducado.", "warning");
                    break;
                case 403:
                    fun.alert("Solicitud denegada.", "warning");
                    localStorage.clear();
                    localStorage.removeItem("token");
                    router.push("/");
                    break;
                case 404:

                    if (localStorage.getItem("token") != null) {
                        fun.alert("El servicio solicitado no existe.");
                    } else {
                        router.push("/");
                    }
                    break;
                case 500:
                    if (localStorage.getItem("token") != null) {
                        fun.alert(error.response.data.ExceptionMessage, "error");
                    } else {
                        fun.alert("[Connection Failed]", "error");
                    }

                    localStorage.setItem("isIdle", false);
                    document.onload = null;
                    document.onmousemove = null;
                    document.onmousedown = null;
                    document.ontouchstart = null;
                    document.onclick = null;
                    document.onscroll = null;
                    localStorage.clear();
                    localStorage.removeItem("token");
                    router.push("/");

                    break;
                case 502:
                    if (localStorage.getItem("token") != null) fun.alert(error.response.data.Message, "error");
                    break;
            }
            return Promise.reject(error);
        }
    }
);

// Load Locales ('en' comes loaded by default)
require("moment/locale/es");

// Choose Locale
moment.locale("es");

Vue.use(VueMoment, { moment });
Vue.directive("focus", {
    inserted: function(el) {
        el.firstChild.firstChild.childNodes[1].childNodes[1].focus();
    },
});

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");



function cargarPagina() {
    showWarningConsole();
    // Resto del código de tu aplicación...
  }

// Ejecutar la función al cargar la página
window.onload = cargarPagina;