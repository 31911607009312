import Service from "./Service";
const resource = "Helper/";
import Vue from "vue";

export default {
    getPhotoWorker(PrsDocumentNumber) {
        return Service.get(resource + "HelperWorkerPhoto", {
            responseType: "blob",
            headers: {
                "Content-Type": "image/jpg",
            },
            params: { PrsDocumentNumber: PrsDocumentNumber },
        });
    },

};