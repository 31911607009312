let children = [];

children.push({
    path: "/profile/:id",
    name: "PROFILE",
    component: () =>
        import ("../views/General/Profile.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Perfil", href: "/profile" },
        ],
    },
});

children.push({
    path: "/error",
    name: "ERROR",
    component: () =>
        import ("../views/General/Error.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Perfil", href: "/error" },
        ],
    },
});


export { children };