import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";


import { children as general } from "@/router//general.js";

Vue.use(VueRouter, {

    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },

});
let children = [];
children.push(...general);



const routes = [{
        path: "*",
        redirect: "/error",
    },
    {
        path: "/:idhome",
        name: "HOME",
        component: Home,
        meta: {
            authenticated: true,
            breadcrumb: [{ text: "Inicio", disabled: false, href: "/#/" }],
        },
        children: children,
    },
];


const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {

    next();
});

export default router;