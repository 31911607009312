import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    mutations: {
        setTimeToken(state, n) {
            state.TimeToken = n;
        },
    },
    actions: {},
    modules: {},
    state: {
        hideConfigButton: false,
        showConfig: false,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        layout: "default"
    },
});