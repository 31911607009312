<template>
<v-app class="scroll">
    <div class="app-container app-theme-white">

        <div class="app-main__outer">
            <div class="app-main__inner">
                <v-main>
                    <router-view />
                </v-main>
            </div>

        </div>
    </div>
</v-app>
</template>

<script>
export default {
    name: "App",
    components: {},
    data() {
        return {
            idhome: null, // Inicializamos la variable "id"
        };
    },
    created() {
        this.idhome = this.$route.params.idhome; // Asignamos el valor del parámetro a "id"
        if(this.idhome !== null && this.idhome !== undefined){
            this.$router.push("/error");
        }
    },
    methods: {
       
    },
    watch: {
        $route(to, from) {},
    },

    mounted() {

    },

    beforeDestroy() {},
};
</script>
