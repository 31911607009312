<template>
<div>
    <v-dialog v-if="showLocal" v-model="showLocal" :persistent="messageProcessing.length==0" width="400">
        <v-row style="margin:auto">

            <v-card  width="100%" color="white" dark>
                <v-progress-linear model-value="10" striped indeterminate color="light-blue" class="mb-0 mt-0"></v-progress-linear>
                <v-card-text v-if="messageProcessing.length == 0">

                    <h5 style="color:black;text-align: center;margin-top:-10px" class="pt-0">Procesando</h5>
                    <h6 style="color:black;text-align: center;margin-bottom:-10px" class="pt-0">Por favor espere...</h6>
                </v-card-text>
                <v-card-text v-else>
                    <div class="pt-4" style="color: black">
                        <h5>Errores encontrados: </h5>
                        <br />
                        {{errors}}
                    </div>
                </v-card-text>
                <v-progress-linear model-value="100" striped color="light-blue"></v-progress-linear>

            </v-card>
        </v-row>
    </v-dialog>
</div>
</template>

<script>
export default {
    props: {
        processing: {
            type: Boolean,
            default: false
        },
        messageProcessing: {
            type: String,
            default: ""
        },
        errors: null
    },
    data: () => ({

    }),

    computed: {
        showLocal: {
            get: function () {
                return this.processing
            },
            set: function (value) {
                this.$emit('close')
            }
        },
    },

};
</script>

<style lang="scss" scoped>

</style>
