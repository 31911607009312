<template>
<div id="app" class="app scroll">
    <router-view></router-view>
</div>
</template>

<script>
export default {
    name: "App",
    data: () => ({
        timeInactiveShow: 0
    }),

}
</script>

<style lang="scss">
@import "assets/style.css";
@import "assets/error.css";
</style>
